





























import './styles/RespondsHeader.scss';
import { Component, Vue } from 'vue-property-decorator';
import { IProject } from '@store/modules/project/Interfaces';
import { namespace } from 'vuex-class';

const NSProject = namespace('storeProject');

@Component({
    name: 'RespondsHeader',

    components: {
        BaseButton: () => import('@components/buttons/BaseButton.vue'),
        BaseRespondsCounter: () => import('@components/BaseRespondsCounter'),
    },
})
export default class RespondsHeader extends Vue {
    @NSProject.Getter('projectData') projectData!: IProject;

    get projectId() {
        return this.projectData ? this.projectData.id.toString() : '';
    }

    goTo(path: string) {
        this.$router.push({ path });
    }

    goToProjectTeam() {
        this.$router.push(`/project/${this.projectId}/project-team`);
    }
}
